<template>
  <div class="login_content">
    <div class="from_box">
      <div class="login_title">{{ Title }}</div>
      <div class="login_subtitle">{{ subTitle }}</div>
      <div class="login_box">
        <div class="login_form" v-if="flag == true">
          <el-form ref="loginForm" :model="loginForm" :rules="loginRules">
            <el-form-item prop="name">
              <el-input type="text" class="name" placeholder="请输入用户名" auto-complete="off" v-model="loginForm.username" @keyup.enter.native="submitForm"></el-input>
              <img src="@/assets/img/login_icn.png"  class="nameLogo"/>
            </el-form-item>
            <el-form-item prop="password">
              <el-input type="password" class="password" placeholder="请输入登录密码" auto-complete="off" v-model="loginForm.password" @keyup.enter.native="submitForm"></el-input>
              <img src="@/assets/img/login_icp.png"  class="pwdLogo"/>
            </el-form-item>
            <el-form-item prop="verifycode">
              <div class="codeBox">
                <el-input type="text" class="code" placeholder="请输入验证码" auto-complete="off" v-model="loginForm.verifycode"  @keyup.enter.native="submitForm"></el-input>
                <div class="identifybox" @click="getIdentCode">
                  <img :src="codeImg" style="width:100%;height:48px;" />
                </div>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="loginBtn" @click.native.prevent="submitForm">登录</el-button>
            </el-form-item>
          </el-form>
        </div>

        <!-- 二维码登录 -->
        <div class="login_qrCode" v-if="flag == false">
          <template v-if="!isShowQr">
            <div class="qrBox" v-if="isShowCode == true">
              <div class="qrImgBox">
                <img :src="qrcode" />
              </div>
              <div class="qrTip">
                <img src="../../assets/img/login_scan.png" alt="">
                <span>请打开防火卫士企业版APP扫码登录</span>
              </div>
            </div>

            <div class="qrBox" v-if="isShowCode == false">
              <img style="width: 120px;height: 120px;" src="../../assets/img/users_default.png" />
              <p style="color: #ABD3F3;margin: 8px 0px;">{{msgUsername}}</p>
              <p style="color: #ABD3F3;">请在手机上确认登陆</p>
            </div>
          </template>

          <template v-if="isShowQr">
            <div class="qrBox">
              <div class="qrImgBox">
                <img :src="downLoadQr" />
              </div>
              <div class="qrTip">
                <img src="../../assets/img/login_scan.png" alt="">
                <span>请打开微信扫码下载</span>
              </div>
            </div>
          </template>
        </div>

       <div class="login_bottom">
         <div class="login_code" @click="loginChange" v-if="flag == true">二维码登录</div>
         <div class="login_pc" @click="loginChange" v-if="flag == false">用户名登录</div>
         <div class="downApp"  @click="downLoadApp">APP下载</div>
       </div>
      </div>
    </div>
  </div>
</template>
<script>
import { verCode, userLogin, getQrCode, QrInfo, DownQr } from '@/http/api/login'
import axios from 'axios'
import Vue from 'vue'
const SM3 = require('sm-crypto')
export default {
  name: 'Login',
  data () {
    return {
      flag: true,
      loginForm: {
        username: '',
        password: '',
        verifycode: ''
      },
      loginRules: {
        username: [
          { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
        ],
        password: [
          { min: 6, max: 18, message: '密码长度在 6 到 18 个字符', trigger: 'blur'}
        ]
      },
      loginCodeUuid: '',
      codeImg: '',
      Title: '',
      subTitle: '',
      qrcode: '',
      isShowCode: true,
      msgUsername: '',
      codeUuid: null,
      timer: '',
      isShowQr: false,
      downLoadQr: ''
    }
  },
  mounted () {
    this.getIdentCode()
  },
  methods: {
    // 获取验证码 标题
    getIdentCode () {
      verCode().then(res => {
        if (res.status === '1') {
          this.loginCodeUuid = res.data.codeuuid
          this.codeImg = res.data.varimage
          this.Title = res.data.logoname
          this.subTitle = res.data.pinyin
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 账号密码登录
    submitForm () {
      this.$refs.loginForm.validate(valid => {
        if (!valid) return
        if (this.loginForm.verifycode === '') {
          this.$message.error('请输入验证码')
          return
        }
        const password = SM3.sm3(this.loginForm.password)
        const params = {
          account: this.loginForm.username,
          password: password,
          userip: this.$url.PATH.userIp,
          codeuuid: this.loginCodeUuid,
          varcode: this.loginForm.verifycode
        }
        // console.log(params)
        userLogin(params).then(res => {
          if (res.status === '1') {
            window.localStorage.setItem('token', 'dzh ' + res.data.usertoken)
            window.localStorage.setItem('loginInfo', JSON.stringify(res.data))
            window.sessionStorage.setItem('LoginState', '1')
            this.$store.commit('setLoginState', !this.$store.getters.loginState)
            if (res.data.roleid === '1') {
              this.$router.push({ path: '/setting' })
            } else if (res.data.roleid === '12') {
              this.$router.push({ path: '/customerCenter' })
              const obj = { path: 'customerCenter', ac: 'customerCenter' }
              window.localStorage.setItem('tabActive', JSON.stringify(obj))
            } else {
              this.$router.push({ path: '/main' })
              const obj = { path: 'home', ac: 'home' }
              window.localStorage.setItem('tabActive', JSON.stringify(obj))
            }
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    loginChange () {
      this.flag = !this.flag
      this.isShowCode = true
      this.isShowQr = false
      if (!this.flag) {
        this.qrLoginClick()
      } else {
        clearInterval(this.timer)
      }
    },
    qrLoginClick () {
      this.isShowCode = true
      getQrCode().then(res => {
        if (res.status === '1') {
          this.qrcode = res.data.codepath
          this.codeUuid = res.data.codeuuid
          this.timer = setInterval(this.getMes, 2000)
        }
      })
    },
    getMes () {
      QrInfo({ codeuuid: this.codeUuid }).then(res => {
        if (res.data.iseff === 2) {
          this.isShowCode = false
          this.msgUsername = res.data.username
        } else if (res.data.iseff === 3) {
          this.loginForm.username = res.data.account
          this.loginForm.password = res.data.password
          this.qrLoginFromSubmit()
        } else if (res.data.iseff === 4) {
          this.qrLoginClick()
        }
      })
    },
    // 二维码登录
    qrLoginFromSubmit () {
      const params = {
        account: this.loginForm.username,
        password: this.loginForm.password,
        userip: this.$url.PATH.userIp,
        codeuuid: this.loginCodeUuid,
        varcode: this.loginCodeUuid
      }
      userLogin(params).then(res => {
        if (res.status === '1') {
          window.localStorage.setItem('token', 'dzh ' + res.data.usertoken)
          window.localStorage.setItem('loginInfo', JSON.stringify(res.data))
          window.sessionStorage.setItem('LoginState', '1')
          this.$store.commit('setLoginState', !this.$store.getters.loginState)
          clearInterval(this.timer)
          if (res.data.companyid === '') {
            this.$router.push({ path: '/setting' })
          } else {
            this.$router.push({ path: '/main' })
            const obj = { path: 'home', ac: 'home' }
            window.localStorage.setItem('tabActive', JSON.stringify(obj))
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    downLoadApp () {
      // window.open(this.$router.resolve({ path: '/DownLoad' }).href, '_blank')
      DownQr({}).then(res => {
        if (res.status === '1') {
          this.flag = false
          this.isShowQr = true
          this.downLoadQr = res.data.appqrcode
        }
      })
    }
  }
}
</script>

<style scoped>
  .login_content{
    width: 100%;
    height: 100%;
    position: relative;
    background: url(../../assets/img/login_bg.png) no-repeat;
    background-size: 100% 100%;
  }
  .from_box{
    z-index: 9999;
    width: 640px;
    height: 500px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -250px;
    margin-left: -320px;
    overflow: hidden;
  }
  .login_title{
    width: 100%;
    text-align: center;
    font-size: 40px;
    font-family: Taipei Sans TC Beta;
    letter-spacing: 18px;
    text-indent: 18px;
    color: #fff;
  }
  .login_subtitle{
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #fff;
    font-family: Roboto;
    letter-spacing: 4px;
  }
  .login_box{
    width: 585px;
    margin: 30px auto 0px;
    height: 400px;
    padding: 30px 50px;
    box-sizing: border-box;
    background: linear-gradient(0deg, rgba(5, 34, 69, 0.8), rgba(3, 24, 52, 0.8), rgba(17, 72, 119, 0.8), rgba(4, 27, 57, 0.8));
    background-size: 100% 100%;
  }
  .login_qrCode,.login_form{
    height: 290px;
  }
  .name>>>.el-input__inner,
  .password>>>.el-input__inner,
  .code>>>.el-input__inner{
    height: 50px;
    padding-left: 60px;
    border: none;
    border-bottom: 1px solid #4685B8;
    border-radius: 0px;
    color: #fff;
    font-size: 14px;
    background-color:transparent;
  }
  .nameLogo,.pwdLogo{
    width: 30px;
    height: 30px;
    position: absolute;
    left: 10px;
    top: 10px;
  }
  .pwdLogo{
    width: 28px;
    height: 28px;
  }
  .code>>>.el-input__inner{
    background:url(../../assets/img/login_icc.png) no-repeat;
    background-size: 28px 28px;
    background-position: 10px 11px;
  }
  .name>>>.el-input__inner:-webkit-autofill,
  .password>>>.el-input__inner:-webkit-autofill,
  .code>>>.el-input__inner:-webkit-autofill{
    -webkit-text-fill-color: #ededed !important;
    transition: background-color 50000s ease-in-out 0s;
  }
  .codeBox{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .code{
    width: 60%;
  }
  .identifybox{
    width: 38%;
    height: 49px;
    cursor: pointer;
    border-bottom: 1px solid #4685B8;
  }
  .loginBtn{
    width: 100%;
    height: 50px;
    margin-top: 20px;
    background: rgba(255, 255, 255, 0.05);
    color: #fff;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid #4685B8;
    border-radius: 0px;
    letter-spacing: 10px;
  }
  .login_bottom{
    width: 45%;
    margin: 20px auto 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #ABD3F3;
    cursor: pointer;
  }
  .login_code,.login_pc{
    cursor: pointer;
  }
  .downApp{
    width: 50%;
    cursor: pointer;
    border-left: 1px solid #ABD3F3;
    text-align: center;
  }
  .qrBox {
    width: 100%;
    box-sizing: border-box;
    padding-top: 7%;
    text-align: center;
  }
  .qrBox p{
    text-align: center;
  }
  .qrBox img {
    width: 160px;
    height: 160px;
  }
  .qrTip {
    font-size: 14px;
    font-weight: 500;
    color: #ABD3F3;
    margin-top: 20px;
  }
  .qrTip img {
    width: 18px;
    height: 18px;
    vertical-align: middle;
    margin-right: 5px;
  }
</style>
